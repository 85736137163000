import React, { useState, forwardRef } from "react";
import { FormField } from "../../form-field/FormField";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Title } from "../../title/Title";
import VevoTerms from "./VevoTerms";
import { Button } from "../../button/Button";
import VevoError from "./VevoError";
import { countries } from "src/utils/countries";

const fields = [
  {
    name: "passport_number",
    label: "Passport Number",
    subtype: "text",
  },
  {
    name: "passport_issue_country",
    label: "Passport Issue Country",
    subtype: "searchable-dropdown",
  },
  {
    name: "date_of_birth",
    label: "Date of birth",
    subtype: "date",
  },
];

interface VevoCheckProps {
  errors: FieldErrors<FieldValues>;
  register: RegisterFn;
  formData: FormState;
  handleValues: (formData?: Partial<FormState>) => void;
  isVevoSuccess: boolean;
  isVevoError: boolean;
}

export const VevoCheck = forwardRef<HTMLDivElement, VevoCheckProps>(
  (
    {
      errors,
      register,
      formData,
      handleValues,
      isVevoSuccess,
      isVevoError,
    }: VevoCheckProps,
    ref
  ) => {
    const [isTermsAccepted, setTermsAccepted] = useState(false);

    return (
      <div className="mt-6">
        <Title text="Vivo check" />
        {/* 
        {isVevoSuccess && (
          <div ref={ref} className="flex flex-col items-center">
            <SuccessCheckmark />
            <div className="mt-2 font-bold">VEVO CHECK SUCCESSFUL</div>
          </div>
        )} */}
        {!isVevoSuccess && (
          <>
            {fields.map((field) => (
              <FormField
                options={countries}
                formData={formData}
                name={field.name}
                key={field.name}
                label={field.label}
                subtype={field.subtype}
                errors={errors}
                register={register}
                handleValues={handleValues}
              />
            ))}
            <div>
              {!isVevoError && (
                <VevoTerms
                  isTermsAccepted={isTermsAccepted}
                  setTermsAccepted={setTermsAccepted}
                />
              )}
              {isVevoError && <VevoError />}

              <div className="flex items-center justify-center">
                <Button
                  disabled={!isTermsAccepted && !isVevoError}
                  label={
                    isVevoError ? "Re-Submit VEVO Check" : "Submit Vevo Check"
                  }
                  size="w-full mt-3"
                  type="submit"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default VevoCheck;
