import React, { ComponentProps } from "react";
import { Checkmark } from "./Checkmark.tsx";

type CheckboxProps = ComponentProps<"input"> & {
  checked: boolean;
  label: any;
  checkboxStyle: "acknowledgement" | "submit" | "";
  id?: string | number;
};

//// Checkbox component
const InputType = ({
  checkboxStyle,
  isChecked,
  onChange,
  id,
  ...props
}: {
  checkboxStyle: "" | "acknowledgement" | "submit";
  isChecked: boolean;
  onChange: any;
  id?: string;
}) => {
  if (checkboxStyle === "acknowledgement" || checkboxStyle === "submit") {
    return (
      <input
        id={id ?? ""}
        type="checkbox"
        className=" w-[24px] h-[24px] shrink-0 appearance-none border-2 border-customGray cursor-pointer rounded-sm bg-customGray checked:border-[#1D85DB] checked:bg-white focus:outline-none checked:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
        checked={isChecked}
        onChange={onChange}
        {...props}
      />
    );
  } else
    return (
      <input
        id={id ?? ""}
        type="checkbox"
        className="w-[24px] h-[24px] shrink-0 appearance-none border-2 border-black rounded-sm bg-white cursor-pointer
                   checked:border-[#1D85DB] checked:bg-white focus:outline-none checked:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
        checked={isChecked}
        onChange={onChange}
        {...props}
      />
    );
};

export const Checkbox = ({
  checked,
  label,
  onChange,
  checkboxStyle,
  id,
  ...props
}: CheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <label htmlFor={id} className="flex relative items-center">
      <div className="relative">
        <InputType
          id={id}
          checkboxStyle={checkboxStyle}
          isChecked={checked}
          onChange={handleChange}
          {...props}
        />
        {checked && (
          <div className="flex absolute inset-0 right-[0.1px] -top-[5px] cursor-pointer">
            <Checkmark />
          </div>
        )}
      </div>
      {label && (
        <span className="flex px-4 pt-[1.5px] text-black font-inter text-sm font-normal md:cursor-pointer">
          {label}
        </span>
      )}
    </label>
  );
};
