interface SuccessMessageProps {
  label?: string;
  heading?: string;
}

export const SuccessMessage = ({ heading, label }: SuccessMessageProps) => {
  return (
    <div className="w-full  flex-shrink-0">
      <div className="text-black font-inter text-3xl font-normal leading-normal ">
        <p>{heading}</p>
      </div>
      <div className="text-black font-inter text-xl font-normal leading-normal mt-10">
        {label}
      </div>
    </div>
  );
};
