import React, { useCallback, useEffect, useRef, useState } from "react";
import { PageWrapper } from "src/components";
import { LandingPage } from "./landing_page";
import { TsAndCsPage } from "./ts_and_cs_page";
import { QuestionnairePage } from "./questionnaire_page";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import { getQuestionnaire } from "src/services/apiService";
import { fetchData } from "src/store/slices/questionnaireSlice";
import { nextPage } from "src/store/slices/pageSlice";
import loadingSpinner from "../../assets/images/animated_loader_gif_n6b5x0.gif";
import { SuccessPage } from "./success_page";
import { useParams } from "react-router-dom";
import PageNotFound from "../404";

const CheckPages = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { checkId } = useParams<{ checkId: string }>();

  const dispatch = useDispatch();
  const fetcher = useCallback(
    async (id: string) => {
      return await getQuestionnaire(id, dispatch);
    },
    [dispatch],
  );

  const { data } = useSWR(checkId, fetcher, {
    revalidateOnFocus: false,
  });

  const [questionnaireData, setQuestionnaireData] = useState();

  useEffect(() => {
    if (data !== null) {
      setQuestionnaireData(data);
      dispatch(fetchData(data));
    }
  }, [data, questionnaireData, dispatch]);

  const scrollToNextPage = (pageIndex: number) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: window.innerHeight * pageIndex, // Use top for vertical scrolling
        behavior: "smooth",
      });
      dispatch(nextPage());
    }
  };

  const page_no = useSelector((state: any) => state?.page_number.page || "");

  useEffect(() => {
    if (page_no === 2 && containerRef.current) {
      containerRef.current.scrollTo({
        top: window.screenTop,
        behavior: "smooth",
      });
    }
  }, [page_no]);

  const error = useSelector((state: any) => state?.error?.status || "");
  const loadSpinner = () => {
    return (
      <div className="flex items-center justify-center h-screen md:min-h-[70vh]">
        <img src={loadingSpinner} alt="description" />
      </div>
    );
  };

  if (!data) {
    return loadSpinner();
  }

  if (error === 400 || error === 404) {
    return <PageNotFound />;
  }
  return (
    <PageWrapper ref={containerRef}>
      {page_no === 1 && (
        <LandingPage scrollToNextPage={() => scrollToNextPage(1)} />
      )}

      {page_no === 2 && (
        <TsAndCsPage scrollToNextPage={() => scrollToNextPage(2)} />
      )}
      {page_no === 3 && (
        <QuestionnairePage scrollToNextPage={() => scrollToNextPage(3)} />
      )}
      {page_no === 4 && <SuccessPage />}
    </PageWrapper>
  );
};

export default CheckPages;
