import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Image } from "../../components/models/image.ts";
import { IdentityDocument } from "../../components/models/identity-document.ts";
import { DocumentStatus } from "../../components/models/identity-document.enum.ts";

interface DraftDocument {
  _id: string;
  status: DocumentStatus; // Change this to match the enum
  _subtype: string;
  _type: string;
}

export interface IdentityDocCaptureState {
  docCaptureModalOpen: boolean;
  draftDocument: DraftDocument;
  getDocumentCollector: any;
  documentImages: any;
  selfieImage: any;
  attachDocument: Image[];
  identityDocument: IdentityDocument;
  error: any;
  documentSubmitted: boolean;
  uploadedDocs?: DocumentCollector[];
}

const initialState: IdentityDocCaptureState = {
  docCaptureModalOpen: false,
  draftDocument: {
    _id: "",
    status: 0,
    _subtype: "",
    _type: "",
  },
  documentImages: [],
  selfieImage: [],
  getDocumentCollector: {},
  attachDocument: [],
  identityDocument: {},
  error: {},
  documentSubmitted: false,
  uploadedDocs: [],
};

export const identityDocCaptureSlice = createSlice({
  name: "identity_doc_capture",
  initialState,
  reducers: {
    addIdentityDoc: (state, action) => {
      if (state?.uploadedDocs?.length === 0) {
        state.uploadedDocs = [action.payload];
      } else {
        state.uploadedDocs = state.uploadedDocs?.map((doc) =>
          doc._id === action.payload._id ? action.payload : doc
        );
      }
    },
    removeIdentityDoc: (state, action) => {
      state.uploadedDocs = state.uploadedDocs?.filter(
        (doc) => doc._id !== action.payload.id
      );
    },
    setDocCaptureData: (
      state,
      action: PayloadAction<Partial<IdentityDocCaptureState>>
    ) => {
      // Only update the fields provided in the action payload
      Object.assign(state, action.payload);
    },
  },
});

export const { setDocCaptureData } = identityDocCaptureSlice.actions;
export default identityDocCaptureSlice.reducer;
