import { forwardRef, useState, useCallback } from "react";
import { ComponentProps } from "react";
import { cn } from "../../../utils/tailwindMerger";
import { Footer, Header } from "../../index";
import ContentWrapper from "../content-wrapper/ContentWrapper";

type PageWrapperProps = ComponentProps<"div"> & {
  className?: string;
};

export const PageWrapper = forwardRef<HTMLDivElement, PageWrapperProps>(
  ({ className, children }, ref) => {
    /////// show box shadow on scroll down
    const [scroll, setScroll] = useState(false);

    const handleScroll = useCallback(
      (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (e.currentTarget.scrollTop > 100) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      },
      []
    );

    return (
      <div
        className={cn(
          "min-h-screen max-h-full h-full overflow-hidden flex flex-col justify-between",
          className
        )}
      >
        <div className="bg-transparent  md:mb-2  ">
          <Header scroll={scroll} />
        </div>

        <ContentWrapper
          onScroll={handleScroll}
          ref={ref}
          className="flex-1  overflow-auto pb-4"
        >
          {children}
        </ContentWrapper>

        <div
          className={cn(
            "flex justify-end items-start pointer-events-none md:mt-2 transition-all duration-300 pt-4"
          )}
          style={{
            boxShadow: scroll ? "0px 0.2px 15px 0px rgb(0 0 0 / 0.1)" : "none",
          }}
        >
          <Footer />
        </div>
      </div>
    );
  }
);
