import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/pageSlice";
import questionnaireData from "./slices/questionnaireSlice";
import profileData from "./slices/profileSlice";
import questionnaireForm from "./slices/questionnaireFormSlice";
import identityDocCapture from "./slices/identityDocCapture.ts";
import errorState from "./slices/errorSlice.ts";
import uploadedNonIdDocsSlice from "./slices/uploadedNonIdDocsSlice.ts";
import statusSlice from "./slices/statusSlice.ts";

export const store = configureStore({
  reducer: {
    page_number: counterReducer,
    questionnaire_data: questionnaireData,
    non_id_docs: uploadedNonIdDocsSlice,
    profileData: profileData,
    questionnaire_form: questionnaireForm,
    identity_doc_capture: identityDocCapture,
    error: errorState,
    status: statusSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
