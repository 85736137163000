import { cn } from "../../../utils/tailwindMerger";
import { months } from "./utils";

type MonthsProps = {
  selectedMonth: number;
  handleSelectMonth: (month: number) => void;
};

/**
 * Renders a component that displays a grid of months.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.selectedMonth - The index of the selected month.
 * @param {Function} props.handleSelectMonth - The function to handle month selection.
 * @returns {JSX.Element} The rendered component.
 */
const Months = ({ selectedMonth, handleSelectMonth }: MonthsProps) => {
  return (
    <div className="grid grid-cols-6 gap-x-3 gap-y-4   px-6 py-4  justify-center items-center h-full ">
      {months.map((month) => (
        <div
          role="button"
          onClick={() => handleSelectMonth(month.value - 1)}
          className={cn(
            " col-span-2 text-left  cursor-pointer hover:text-black hover:bg-cielo rounded py-1 cursor-pointer px-1 text-sm",
            months[selectedMonth].value === month.value ? "bg-azure" : ""
          )}
          key={month.value}
        >
          {month.name}
        </div>
      ))}
    </div>
  );
};

export default Months;
