import React from "react";
import { Checkbox } from "../checkbox/Checkbox";
import { Button } from "../button/Button";
import { formatString } from "../../../utils/utils";

export interface SelectableCardProps {
  appearance:
    | "hasDescription"
    | "noDescription"
    | "hasButton"
    | "hasData"
    | "requiredDocuments";
  label: string;
  buttonBackgroundColor?: string;
  labelId?: string;
  checked?: boolean;
  info?: string;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  data?: any;
  passportNumber?: string;
  issueCountry?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onclick?: () => void;
}

export const SelectableCard = ({
  checked,
  label,
  labelId,
  info,
  appearance = "noDescription",
  buttonBackgroundColor,
  buttonLabel,
  buttonDisabled,
  passportNumber,
  issueCountry,
  data,
  onChange,
  onclick,
  ...props
}: SelectableCardProps) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const borderStyle = `ring-inset ${checked ? "ring-2 ring-[var(--Primary-Light-Azure,#1D85DB)]" : "ring-1 ring-[#777777]"}`;

  if (appearance === "noDescription") {
    return (
      <label
        htmlFor={labelId}
        className="w-full flex flex-col items-start cursor-pointer"
      >
        <div
          className={`w-full md:w-[455px] h-full p-2.5 rounded-md bg-[var(--Neutrals-Nimbus,#F8F9F9)] ${borderStyle}`}
        >
          <div className="flex justify-between items-center">
            <p className="text-left text-black font-inter text-sm font-semibold leading-[1.28571] uppercase">
              {label}
            </p>
            <div className="pl-20">
              <Checkbox
                id={labelId}
                checked={checked ? true : false}
                label={""}
                checkboxStyle={""}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </label>
    );
  }

  if (appearance === "hasDescription") {
    return (
      <label
        htmlFor={labelId}
        className={`cursor-pointer relative flex w-full md:w-[455px] flex-col p-5 gap-4 self-stretch rounded-md bg-[var(--Neutrals-Nimbus,#F8F9F9)] ${borderStyle}`}
      >
        <div className="text-left flex flex-col items-start gap-2">
          <p className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter">
            {label}
          </p>
          {info && (
            <p className="text-[var(--Neutrals-Monsoon)] text-sm font-normal leading-[18px]">
              {info}
            </p>
          )}
        </div>
        <div className="absolute right-[0.625rem] top-[1rem]">
          <Checkbox
            id={labelId}
            checked={checked ? true : false}
            label={""}
            checkboxStyle={""}
            onChange={handleCheckboxChange}
          />
        </div>
      </label>
    );
  }

  if (appearance === "hasButton") {
    return (
      <div className="flex w-full md:w-[455px] flex-col p-5 gap-4 self-stretch rounded-md border-2 border-[#ededed] bg-[#ededed]">
        <div className="flex flex-col items-center gap-2">
          <p className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter">
            {label}
          </p>
          {buttonLabel && (
            <Button
              onClick={onclick}
              size="w-full"
              label={buttonLabel as string}
              disabled={buttonDisabled}
            />
          )}
        </div>
      </div>
    );
  }

  if (appearance === "hasData" && data) {
    return (
      <div className="flex w-full md:w-[455px] flex-col p-5 gap-4 self-stretch rounded-md border-2 border-[#ededed] bg-[#ededed]">
        <div className="flex flex-col items-center gap-2">
          <p className="text-[var(--Neutrals-Monsoon)] text-sm font-semibold uppercase leading-[18px] font-inter">
            {label}
          </p>
          <div className="flex flex-col items-center">
            {Object.keys(data).map((key) => (
              <p className="text-[var(--Neutrals-Monsoon)] text-sm font-normal leading-[18px] font-semibold uppercase leading-[18px] font-inter">
                {formatString(key)}: {data[key]}
              </p>
            ))}
          </div>
          {buttonLabel && (
            <Button
              onClick={onclick}
              size="w-full"
              label={buttonLabel as string}
              disabled={buttonDisabled}
            />
          )}
        </div>
      </div>
    );
  }

  return <div className=""></div>;
};
