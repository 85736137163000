import { ComponentProps } from "react";
import { cn } from "../../../utils/tailwindMerger";

type CellProps = ComponentProps<"div"> & {
  className?: string;
};
const Cell = ({ className, ...props }: CellProps) => {
  return (
    <div
      className={cn("text-center uppercase text-xs", className)}
      {...props}
    />
  );
};

export default Cell;
