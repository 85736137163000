import React, { useRef, useState } from "react";
import {
  InputField,
  InputFieldProps,
} from "../../form-field/input-field/InputField";
import { cn } from "../../../../utils/tailwindMerger";
import useClickOutside from "../../.././../hooks/useClickOutSide";
import Results from "./Results";

type SearchableDropdownProps = Omit<
  InputFieldProps,
  "defaultValue" | "value"
> & {
  inputClassName?: string;
  onResultSelect: (val: any) => void;
  isMulti?: boolean;
  options: {
    label: string;
    value: string;
  }[];
  className?: string;
  initialValue?: string;
  value: string;
};

/**
 * SearchableDropdown component.
 *
 * @component
 * @param {string} inputClassName - The class name for the input field.
 * @param {Option[]} options - The options for the dropdown.
 * @param {Function} onChange - The callback function when the dropdown value changes.
 * @param {string} value - The current value of the dropdown.
 * @param {string} className - The class name for the component.
 * @param {boolean} isMulti - Indicates if the dropdown allows multiple selections.
 * @param {string} initialValue - The initial value of the dropdown.
 * @param {Function} onResultSelect - The callback function when a result is selected.
 * @returns {JSX.Element} The rendered SearchableDropdown component.
 */
export const SearchableDropdown = ({
  inputClassName,
  options,
  onChange,
  value,
  className,
  isMulti = false,
  initialValue,
  onResultSelect,
  ...props
}: SearchableDropdownProps) => {
  const { ...inputFieldProps } = props;

  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const handleAddOption = (option: Option) => {
    if (!isMulti) {
      return;
    }
    setSelectedOptions(
      selectedOptions.includes(option)
        ? selectedOptions.filter((selectedOption) => selectedOption !== option)
        : [...selectedOptions, option]
    );
    setResults((prev) => prev.filter((result) => result !== option));
  };

  const handleRemoveOption = (option: Option) => {
    if (!isMulti) {
      return;
    }
    setSelectedOptions(
      selectedOptions.filter((selectedOption) => selectedOption !== option)
    );
    setResults((prev) => [...prev, option]);
  };

  const ResultsRef = useRef<HTMLDivElement>(null);

  useClickOutside(ResultsRef, () => setShowResults(false));

  const [selectedOption, setSelectedOption] = useState<string>(
    initialValue || value
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue === "") {
      const filteredOptions = options.filter((option) => {
        return !selectedOptions.includes(option);
      });
      setResults(filteredOptions);
    } else {
      setResults((prev) =>
        prev.filter((option) =>
          option.label.toLowerCase().includes(newValue.toLowerCase())
        )
      );
    }
  };

  return (
    <div className={cn("flex flex-col gap-y-2 relative w-full", className)}>
      <div className="flex">
        <InputField
          handleRemoveOption={handleRemoveOption}
          onClick={() => setShowResults(true)}
          className={cn("w-full", inputClassName)}
          {...inputFieldProps}
          handleChange={onInputChange}
          selectedOptions={selectedOptions}
        />
      </div>

      {showResults && value !== "" && (
        <div ref={ResultsRef} className={cn("relative  z-[100]  w-full")}>
          <Results
            selectedOption={selectedOption}
            handleAddOption={handleAddOption}
            handleRemoveOption={handleRemoveOption}
            onResultSelect={onResultSelect}
            setSelectedOption={setSelectedOption}
            setShowResults={setShowResults}
            results={results}
          />
        </div>
      )}
    </div>
  );
};
