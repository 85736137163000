import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "@simplecitizen/gdl-react-ui-components/dist/style.css";
import "./App.css";
import CheckPages from "./pages/check/index";
import PageNotFound from "./pages/404";

const MainRoutes = () => {
  return (
    <div className="flex flex-col w-screen h-screen overflow-y-auto overflow-x-hidden">
      <Routes>
        <Route path="/" element={<PageNotFound />} />
        <Route path="/check/:checkId" element={<CheckPages />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

function App() {
  useEffect(() => {
    console.log(`Git Hash: ${process.env.REACT_APP_GIT_HASH}`);
  }, []);
  return (
    <Router>
      <MainRoutes />
    </Router>
  );
}

export default App;
