import React from "react";
import WRLogo from "../../../assets/images/workRight_Logo.png";

interface LogoProps {
  label?: string;
  src?: string;
}

const defaultLabel = "Right to Work Confirmation Portal";

export const DefaultLogo = ({ label = defaultLabel, src }: LogoProps) => {
  return (
    <div className="">
      {/* desktop logo */}
      <div className="w-[25%] md:flex hidden flex-shrink-0 ">
        <img
          className="w-full h-full"
          src={src ? src : WRLogo}
          alt="Logo"
          width={359}
          height={75}
        />
      </div>
      {/* mobile logo */}
      <div className="w-[50%] md:hidden flex flex-shrink-0">
        <img
          className="w-full h-full pt-2"
          src={src ? src : WRLogo}
          alt="Logo"
          width={200}
          height={75}
        />
      </div>
      <h1 className="font-inter lg:text-xl xl:text-xl md:text-xl font-normal leading-[29.05px] text-left ">
        {label}
      </h1>
    </div>
  );
};
