interface LineButtonProps {
  label: string;
  handleClick: () => void;
}

export const LineButton = ({ label, handleClick }: LineButtonProps) => {
  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-xs text-black font-inter font-semibold underline mt-3"
    >
      {label}
    </button>
  );
};
