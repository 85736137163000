import { Title, SelectableCard, LineButton } from "../../../../index";

interface WorkStatusProps {
  workStatus: StatusType[];
  selectedStatusType: StatusType | null;
  handleWorkStatus: (workStatus: StatusType) => void;
  resetWorkStatus: () => void;
}

export const WorkStatus = ({
  workStatus,
  selectedStatusType,
  handleWorkStatus,
  resetWorkStatus,
}: WorkStatusProps) => {
  const isSelected = (status: StatusType) =>
    selectedStatusType?._id === status._id;

  return (
    <div className="mt-6">
      <Title text="Work Status" />
      <div className="flex flex-col gap-3">
        {workStatus?.map((type) => (
          <div key={type._id}>
            <SelectableCard
              label={type.name}
              info={type?.description?.en}
              buttonBackgroundColor={""}
              appearance="hasDescription"
              checked={isSelected(type)}
              onChange={(e) => {
                isSelected(type) ? resetWorkStatus() : handleWorkStatus(type);
              }}
            />
            {selectedStatusType && (
              <LineButton
                label="Select other work status"
                handleClick={resetWorkStatus}
              />
            )}
            {isSelected(type) && type?.instructions && (
              <div className="font-inter font-normal text-black mt-4">
                <Title text="Instructions" />
                <div
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: type?.instructions }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
