import React from "react";
import { FormField } from "../../form-field/FormField";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Title } from "../../title/Title";

const typesOfVisas: Option[] = [
  { label: "Visitor Visa (subclass 600)", value: "subclass 600" },
  { label: "Student Visa (subclass 500)", value: "subclass 500" },
  {
    label: "Partner Visa (subclasses 820 and 801)",
    value: "subclasses 820 and 801",
  },
  { label: "Working Holiday Visa (subclass 417)", value: "subclass 417" },
  { label: "Other", value: "Other" },
];

const fields = [
  {
    name: "visa_application_date",
    label: "Visa Application Date",
    subtype: "date",
  },
  {
    name: "other_visasa_held",
    label: "List other Australian visas you have held",
    subtype: "searchable-dropdown",
    placeholder: "",
    options: typesOfVisas,
    isMulti: true,
  },
  {
    name: "current_visa_subclass",
    label: "What is your course of study while in Australia?",
    subtype: "text",
  },
];

type VevoCheckAdditonalQuestionsProps = {
  errors: FieldErrors<FieldValues>;
  register: RegisterFn;
  formData: FormState;
  handleValues: (formData?: Partial<FormState>) => void;
};

const VevoCheckAdditonalQuestions = ({
  errors,
  register,
  formData,
  handleValues,
}: VevoCheckAdditonalQuestionsProps) => {
  return (
    <div>
      <Title text="Additional Questions" />
      {fields.map((field) => (
        <FormField
          options={field.options}
          formData={formData}
          key={field.name}
          errors={errors}
          register={register}
          handleValues={handleValues}
          {...field}
        />
      ))}
    </div>
  );
};

export default VevoCheckAdditonalQuestions;
