import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components";
import { requestOTP } from "src/services/apiService";
import { useParams } from "react-router-dom";

export interface LandingPageProps {
  scrollToNextPage?: any;
}

export const LandingPage = ({ scrollToNextPage }: LandingPageProps) => {
  const dispatch = useDispatch();
  const { checkId } = useParams<{ checkId: string }>();

  const handleClick = () => {
    scrollToNextPage();
    requestOTP(checkId, "check", dispatch);
  };

  const message = useSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?.message ?? ""
  );

  return (
    <div className="h-full flex items-center w-full justify-center md:min-h-[70vh]">
      <div className="w-80 sm:w-96 md:w-500 lg:w-600 xl:w-700 xxl:w-800 ">
        <h1 className="font-inter text-xl font-normal leading-25 text-left">
          <div dangerouslySetInnerHTML={{ __html: message || "WorkRight has been contracted by your potential new employer to perform a Right to Work check to ensure " +
                "compliance with local employment laws. This process should only take a few minutes to complete. If you " +
                "do not have all the document you need right now, you can click on the same link again later to continue. " }}/>
        </h1>
        <div className="flex items-center justify-center pt-16">
          <Button
            size="w-80 sm:w-96 md:w-96 lg:w-96 xl:w-96 xxl:w-96"
            label="Begin Right to Work Check"
            onClick={handleClick}
            bgColor="#243745"
          />
        </div>
      </div>
    </div>
  );
};
