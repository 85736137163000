import { z } from "zod";

const failureMessage = z.object({
  en: z.string().optional(),
});

const rule = z.object({
  validationType: z.string().optional(),
  test: z.string().optional(),
  tolerance: z.string().optional(),
  failureMessage: failureMessage.optional(),
});

const validator = z.object({
  slug: z.any().optional(),
  fieldType: z.string().nullable(),
  rules: z.array(rule),
});

const config = z.object({
  authenticate: z.boolean().optional(),
  facialComparison: z.boolean().optional(),
  newDocumentRequired: z.boolean().optional(),
  required: z.boolean().optional(),
  validators: z.array(validator.optional()),
});

// Validation schema
// const validationSchema = z.object({
//   _Id: z.string(),
//   _Type: z.string(),
//   field: z.string(),
//   validationType: z.string(),
//   test: z.string(),
//   tolerance: z.string(),
//   failureMessage: z.string(),
// });

// DocumentCollector schema
const documentCollectorSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  _subtype: z.string().nullable(),
  // key: z.string(),
  name: z.object({ en: z.string() }).optional(),
  documentType: z.string(),
  config: config,
  // validation: z.array(validationSchema),
});

// Option schema
const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

// DataCollector schema
const dataCollectorSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  _subtype: z.string(),
  key: z.string(),
  label: z.string(),
  options: z.array(optionSchema),
});

// StatusType schema
const statusTypeSchema = z.object({
  _id: z.string(),
  _type: z.string(),
  name: z.string(),
  instructions: z.any().optional(),
  dataCollectors: z.array(dataCollectorSchema).nullable(),
  documentCollectors: z.array(documentCollectorSchema),
});

// StatusCategory schema
const statusCategorySchema = z.object({
  _id: z.string(),
  _type: z.string(),
  name: z.string(),
  isCitizenship: z.boolean(),
  statusTypes: z.array(statusTypeSchema),
});

// Jurisdiction schema
const jurisdictionSchema = z.object({
  name: z.string().optional(),
  code: z.string().optional(),
  statusCategories: z.array(statusCategorySchema).optional(),
});

// Main schema
export const schema = z.object({
  firstName: z.string().min(1, "First name is required").optional(),
  middleName: z.string().nullable(),
  lastName: z.string().min(1, "Last name is required").optional(),
  suffux: z.string().optional(),
  jurisdiction: jurisdictionSchema,
  statusCategory: statusCategorySchema,
  statusType: statusTypeSchema,
  bratwursttype: z.string().optional(),
});

export const vevoSchema = z.object({
  passport_number: z
    .string({ message: "Passport number is required" })
    .optional(),
  date_of_birth: z.string({ message: "Date of birth is required" }).optional(),
  passport_issue_country: z
    .string({
      message: "Passport issue country is required",
    })
    .optional(),
});
