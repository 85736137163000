import { useMemo } from "react";
import { LineButton, SelectableCard, Title } from "../../../../index";

interface StatusCategoriesProps {
  showIsCitizenQuestion: boolean;
  statusCategories: StatusCategory[] | null;
  isCitizen: boolean;
  statusCategoryRef?: React.Ref<HTMLDivElement>;
  selectedStatusCategory: StatusCategory | null;
  resetStatusCategory: () => void;
  handleStatusCategory: (cat: StatusCategory) => void;
  showStatusCategories: boolean;
}

export const StatusCategories = ({
  showIsCitizenQuestion,
  statusCategories,
  statusCategoryRef,
  selectedStatusCategory,
  isCitizen,
  resetStatusCategory,
  handleStatusCategory,
  showStatusCategories,
}: StatusCategoriesProps) => {
  const isSelected = (cat: StatusCategory) =>
    selectedStatusCategory?._id === cat._id;
  const categoriesToDisplay = useMemo(() => {
    if (isCitizen) {
      return statusCategories?.filter((cat) => cat.isCitizenship);
    }
    return statusCategories?.filter((cat) => !cat.isCitizenship);
  }, [statusCategories, isCitizen]);

  return (
    <>
      {statusCategories && showStatusCategories && categoriesToDisplay && (
        <div ref={statusCategoryRef}>
          <div className="mt-6">
            <Title text="Work Status Category" />
            <div className="flex flex-col gap-3">
              {categoriesToDisplay.length > 0 ? (
                categoriesToDisplay.map((cat) => (
                  <SelectableCard
                    key={cat._id}
                    label={cat.name}
                    labelId={cat._id}
                    appearance="noDescription"
                    checked={isSelected(cat)}
                    onChange={(e) => {
                      isSelected(cat)
                        ? resetStatusCategory()
                        : handleStatusCategory(cat);
                    }}
                  />
                ))
              ) : (
                <p className="text-center">No status categories available</p>
              )}
            </div>
          </div>

          {selectedStatusCategory && (
            <LineButton
              label="Select other status category"
              handleClick={resetStatusCategory}
            />
          )}
        </div>
      )}
    </>
  );
};
