import { useState } from "react";

interface SelectFieldProps {
  name: string;
  error: string;
  value: string;
  options: Option[];
  size?: string;
  label?: string;
  disabled?: boolean;
  register: RegisterFn;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const SelectField = ({
  name,
  options,
  label,
  size,
  error,
  value,
  disabled,
  register,
  handleChange,
}: SelectFieldProps) => {
  const selectRegister = register(name);
  const [selectedValue, setSelectedValue] = useState(value);

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    selectRegister.onChange(e);
    handleChange(e);
  };

  const renderOptions = (options: string[] | Option[]) => {
    return options.map((option, index) => {
      if (typeof option === "string") {
        return (
          <option className="capitalize" key={index} value={option}>
            {option}
          </option>
        );
      } else {
        return (
          <option
            className="capitalize"
            key={option.value}
            value={option.value}
            disabled={disabled}
          >
            {option.value}
          </option>
        );
      }
    });
  };

  return (
    <div className="mb-3">
      <label
        htmlFor={name}
        className="block font-inter font-medium text-sm leading-4 mb-1 text-black capitalize"
      >
        {label}
      </label>
      <div className="font-inter space-y-1.5">
        <select
          id={name}
          className={`${size} w-full h-3xl rounded-lg outline-none focus:ring-1 border ${error ? "border-red-500 ring-red-500" : disabled ? "border-[#949494] ring-[#949494]" : "border-[#777777] ring-[#777777]"}   bg-white shadow-[2px_4px_4px_rgba(0,0,0,0.13)] p-2 ${disabled ? "text-[#949494]" : "text-black"} text-xs font-normal capitalize`}
          onChange={handleOnChange}
          defaultValue={selectedValue}
          disabled={disabled}
        >
          <option key="placeholder" value="" disabled>
            Select one
          </option>
          {options && renderOptions(options)}
        </select>
        {error && <p className="font-inter text-xs text-red-500">{error}</p>}
      </div>
    </div>
  );
};
