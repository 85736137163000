import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  useCallback,
  useEffect,
} from "react";
import {
  DocumentReaderWebComponent,
  DocumentReaderDetailType,
  defineComponents,
  DocumentReaderService,
  InternalScenarios,
} from "@regulaforensics/vp-frontend-document-components";
import { IdentityDocumentImages } from "../../../models/identity-document-images";
import { ImageKey } from "../../../models/image-key.enum";
import { setDocCaptureData } from "../../../../store/slices/identityDocCapture.ts";
import { useDispatch } from "react-redux";

export interface IdentityDocumentReaderProps {
  onDocumentProcessFinished?: (documentImages: IdentityDocumentImages) => void;
  onDocumentReaderClosed?: () => void;
}

declare global {
  interface Window {
    RegulaDocumentSDK: DocumentReaderService;
  }

  interface HTMLElementTagNameMap {
    "document-reader": DocumentReaderWebComponent;
  }

  namespace JSX {
    interface IntrinsicElements {
      "document-reader": DetailedHTMLProps<
        HTMLAttributes<HTMLElement>,
        DocumentReaderWebComponent
      >;
    }
  }
}

export const IdentityDocumentReader = ({
  onDocumentProcessFinished,
  onDocumentReaderClosed,
}: IdentityDocumentReaderProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const elementRef = React.useRef<DocumentReaderWebComponent>(null);

  const regulaKey = process.env.REACT_APP_REGULA_KEY;

  const dispatch = useDispatch();

  const listener = useCallback(
    (data: CustomEvent<DocumentReaderDetailType>) => {
      console.log("data.detail.action:", data.detail.action);
      if (data.detail.action === "PROCESS_FINISHED") {
        const status = data.detail.data?.status;
        const isOkStatus = status === 1 || status === 2;

        if (!isOkStatus || !data.detail.data?.response) return;

        if (
          data.detail.data.response?.images &&
          data.detail.data.response?.images?.fieldList?.length > 0
        ) {
          const documentImagesData =
            data.detail.data.response.images.fieldList.find(
              (imageObject: { fieldName: string }) =>
                imageObject.fieldName === "Document front side",
            );

          if (
            !!documentImagesData &&
            documentImagesData.valueList?.length > 0
          ) {
            const documentImages: IdentityDocumentImages = {};
            const frontSideImage = documentImagesData.valueList.find(
              (imageValue: { pageIndex: number; containerType: number }) =>
                imageValue.pageIndex === 0 && imageValue.containerType === 1,
            );
            const backSideImage = documentImagesData.valueList.find(
              (imageValue: { pageIndex: number; containerType: number }) =>
                imageValue.pageIndex === 1 && imageValue.containerType === 1,
            );

            if (!!frontSideImage && frontSideImage.value) {
              const frontSideImageBase64String = frontSideImage.value;

              documentImages.front = {
                format: "base64",
                source: frontSideImageBase64String,
                key: ImageKey.Front,
              };
            }

            if (!!backSideImage && backSideImage.value) {
              const backSideImageBase64String = backSideImage.value;

              documentImages.back = {
                format: "base64",
                source: backSideImageBase64String,
                key: ImageKey.Back,
              };
            }

            if (documentImages.front || documentImages.back) {
              dispatch(setDocCaptureData({ documentImages: documentImages }));
              if (onDocumentProcessFinished) {
                onDocumentProcessFinished(documentImages);
              }
            }
          }
        }
      }

      if (data.detail?.action === "CLOSE") {
        if (onDocumentReaderClosed) {
          onDocumentReaderClosed();
        }
      }
    },
    [onDocumentProcessFinished, onDocumentReaderClosed, dispatch],
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const currentElement = elementRef.current;
    if (currentElement) {
      currentElement.addEventListener(
        "documentEvent",
        listener as EventListener,
      );
      console.log("Event listener added");
    }
    return () => {
      if (currentElement) {
        currentElement.removeEventListener(
          "documentEvent",
          listener as EventListener,
        );
      }
    };
  }, [listener, elementRef]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const containerCurrent = containerRef.current;

    window.RegulaDocumentSDK = new DocumentReaderService();
    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        scenario: "MrzAndLocate" as InternalScenarios,
        multipageProcessing: true,
      },
    };
    window.RegulaDocumentSDK.imageProcessParam = {
      processParam: {
        scenario: "MrzAndLocate" as InternalScenarios,
      },
    };

    const base64License = regulaKey;
    // To use the document-reader component on test environments, you have to set the base64 license
    defineComponents().then(() =>
      window.RegulaDocumentSDK.initialize({ license: base64License }),
    );

    if (!containerCurrent) return;

    containerCurrent.addEventListener("document-reader", listener);

    return () => {
      window.RegulaDocumentSDK.shutdown();
      containerCurrent.removeEventListener("document-reader", listener);
    };
  }, [listener, regulaKey]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const elementRefCurrent = elementRef.current;

    if (!elementRefCurrent) return;

    elementRefCurrent.settings = {
      startScreen: true,
      changeCameraButton: true,
      regulaLogo: false,
      multipageProcessing: true,
      locale: regulaKey,
    };
  }, [regulaKey]);

  return (
    <div
      ref={containerRef}
      className="bg-white p-2 rounded-2xl md:max-w-[43rem]"
    >
      <document-reader
        ref={elementRef}
        className="font-inter text-[#1d85db] hover:text-[#1664a4] active:text-[#1d85db] rounded-2xl"
      ></document-reader>
    </div>
  );
};
