import { FieldErrors, FieldValues } from "react-hook-form";
import { FormField, Title } from "../../../index";

interface WorkRightProfileProps {
  profile: Profile;
  errors: FieldErrors<FieldValues>;
  register: RegisterFn;
}

export const WorkrightProfile = ({
  profile,
  register,
  errors,
}: WorkRightProfileProps) => {
  return (
    <div className="max-auto flex flex-col gap-1 min-w-full md:min-w-[455px] md:max-w-[455px]">
      <Title text="Biographical Information" />
      {Object.keys(profile).map((field) => (
        <FormField
          name={field}
          key={field}
          label={field}
          subtype={"text"}
          errors={errors}
          register={register}
          disabled={
            field === "middleName"
              ? false
              : errors?.[field]?.message === undefined
          }
        />
      ))}
    </div>
  );
};
