interface ErrorMessageProps {
  label?: string;
  heading?: string;
}

export const Error404Message = ({
  heading = "404",
  label = "Page Not Found",
}: ErrorMessageProps) => {
  return (
    <div className="flex flex-col items-center justify-center grid grid-cols-2 gap-x-4">
      <div>
        <img alt="work right" src="/images/workRight_Logo.png" />
      </div>
      <div>
        <h1 className="text-black font-inter text-7xl font-normal leading-normal">
          {heading}
        </h1>
        <h3 className="text-black font-inter text-2xl font-bold leading-normal">
          Check Not Found
        </h3>
        <p className="text-black font-inter  font-normal leading-normal mt-1">
          We couldn’t find any details for the provided check. The link may be
          invalid or expired.
        </p>
        <p className="text-black font-inter  font-normal leading-normal mt-3">
          Please ensure the URL is correct, or contact support if the issue
          persists.
        </p>
      </div>
    </div>
  );
};
