import ContentWrapper from "src/components/lib/content-wrapper/ContentWrapper";
import { Error404Message } from "src/components/lib/error-messages/Error404Message";

const PageNotFound = () => {
  return (
    <div className="min-h-screen max-h-full h-full overflow-hidden flex flex-col justify-between">
      <ContentWrapper className="flex-1  overflow-auto pb-4 flex justify-center">
        <Error404Message />
      </ContentWrapper>
    </div>
  );
};

export default PageNotFound;
