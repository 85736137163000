import React, { useState } from "react";
import { Checkbox } from "../../checkbox/Checkbox";
import { truncateText } from "../../../../utils/utils";

const termsContent = `Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
        odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
        voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
        quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
        eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
        voluptatem. Sed ut perspiciatis unde omnis iste natus error sit
        voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
        ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
        aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
        qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
        dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia
        non numquam eius modi tempora incidunt ut labore et dolore magnam
        aliquam quaerat voluptatem.`;

const termsContentMinLength = 600;

interface VevoTermsProps {
  isTermsAccepted: boolean;
  setTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const VevoTerms = ({ isTermsAccepted, setTermsAccepted }: VevoTermsProps) =>
  // { handleCheck }: VevoTermsProps
  {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };

    return (
      <div>
        <h2 className=" font-semibold mb-2">VEVO Terms and Conditions</h2>
        <div className="space-y-4 max-w-none prose prose-headings:text-xl leading-6 font-inter max-sm:mb-4 relative mb-4">
          {isTruncated
            ? truncateText(termsContent, termsContentMinLength)
            : termsContent}
          {isTruncated && termsContent.length > termsContentMinLength && (
            <div className=" absolute -mt-[5rem]  w-full h-20 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
          )}
          {isTruncated && termsContent.length > termsContentMinLength && (
            <span
              onClick={toggleTruncate}
              className="block mb-2 text-blue-500 cursor-pointer"
            >
              {isTruncated ? "Read More" : "Show Less"}
            </span>
          )}
        </div>

        <Checkbox
          id={"vevo_terms"}
          checked={isTermsAccepted}
          label={"I Agree to the VEVO Terms and Conditions above"}
          checkboxStyle="acknowledgement"
          onChange={(e) => {
            setTermsAccepted(e.target.checked);
          }}
        />
      </div>
    );
  };

export default VevoTerms;
