import React from "react";
import { cn } from "../../../utils/tailwindMerger";

const initialYear = 1924;

interface YearsProps {
  handleSelectYear: (year: number) => void;
  selectedYear: number;
  setShowMonths: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Renders a component that displays a list of years.
 *
 * @param handleSelectYear - The callback function to handle the selection of a year.
 * @param selectedYear - The currently selected year.
 * @param setShowMonths - The function to set whether to show the months.
 */
const Years = ({
  handleSelectYear,
  selectedYear,
  setShowMonths,
}: YearsProps) => {
  return (
    <div className="grid grid-cols-6 gap-x-3 gap-y-4   px-6 py-4  justify-center items-center h-full ">
      {Array.from({ length: 1000 }, (_, i) => i + 1).map((num) => (
        <div
          role="button"
          onClick={() => {
            handleSelectYear(initialYear + num);
            setShowMonths(true);
          }}
          className={cn(
            " col-span-2 text-center  cursor-pointer hover:text-black hover:bg-cielo rounded py-1 cursor-pointer text-sm",
            selectedYear === initialYear + num ? "bg-azure" : ""
          )}
          key={num}
        >
          {initialYear + num}
        </div>
      ))}
    </div>
  );
};

export default Years;
