import React from "react";
import Cell from "./Cell";
import { cn } from "../../../utils/tailwindMerger";
import { days } from "./utils";

type DaysProps = {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedMonth: number;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
  selectedYear: number;
  className?: string;
  dayClassName?: string;
  dateClassName?: string;
  yearClassName?: string;
  monthClassName?: string;
  darkMode?: boolean;
  selectedDay: number;
  setSelectedDay: React.Dispatch<React.SetStateAction<number>>;
  onChange: (date: Date) => void;
};

/**
 * Renders a calendar grid displaying the days of a selected month.
 *
 * @param selectedYear - The selected year.
 * @param selectedMonth - The selected month.
 * @param selectedDay - The selected day.
 * @param selectedDate - The selected date.
 * @param setSelectedDate - A function to set the selected date.
 * @param className - Additional CSS class names for the component.
 * @param dateClassName - Additional CSS class names for the date cells.
 * @param dayClassName - Additional CSS class names for the day cells.
 * @param darkMode - Indicates whether the component should be displayed in dark mode.
 * @param onChange - A callback function triggered when a date is selected.
 */
const Days = ({
  selectedYear,
  selectedMonth,
  selectedDay,
  selectedDate,
  setSelectedDate,
  className,
  dateClassName,
  dayClassName,
  darkMode,
  onChange,
}: DaysProps) => {
  const firstDay = new Date(selectedYear, selectedMonth, 1).getDay();
  const lastDay = new Date(selectedYear, selectedMonth + 1, 0).getDay();
  const monthDays = new Date(selectedYear, selectedMonth + 1, 0).getDate();
  const dayCellClassName =
    "text-center rounded-full h-5 w-5 cursor-pointer flex items-center justify-center";
  return (
    <div
      className={cn(
        "grid grid-cols-7 gap-x-3 gap-y-4   px-6 py-4  h-full",
        className
      )}
    >
      {days.map((day) => (
        <Cell
          key={day}
          className={cn(
            `text-center font-bold `,
            dayClassName,
            darkMode ? "text-cielo" : "text-steel"
          )}
        >
          {day}
        </Cell>
      ))}
      {Array.from({ length: firstDay }, (_, i) => i).map((_) => (
        <Cell
          key={_}
          className={cn(
            dayCellClassName,
            darkMode ? "text-muted" : "text-neutral-stratus"
          )}
        >
          {new Date(selectedYear, selectedMonth, -firstDay + _ + 1).getDate()}
        </Cell>
      ))}
      {Array.from({ length: monthDays }, (_, i) => i + 1).map((day) => (
        <Cell
          role="button"
          onClick={() => {
            setSelectedDate(new Date(selectedYear, selectedMonth, day));
            onChange(new Date(selectedYear, selectedMonth, day));
            // setSelectedDay(day);
          }}
          key={day}
          className={cn(
            dayCellClassName,
            selectedDate.getDate() === day &&
              selectedDate.getMonth() === selectedMonth &&
              selectedDate.getFullYear() === selectedYear
              ? "bg-azure"
              : "hover:bg-cielo hover:text-black",
            dateClassName
          )}
        >
          {day}
        </Cell>
      ))}
      {Array.from({ length: 6 - lastDay }, (_, i) => i).map((_) => (
        <Cell
          key={_}
          className={cn(
            dayCellClassName,
            darkMode ? "text-muted" : "text-neutral-stratus"
          )}
        >
          {new Date(selectedYear, selectedMonth + 1, _ + 1).getDate()}
        </Cell>
      ))}
    </div>
  );
};

export default Days;
