import React from "react";

const VevoError = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mb-3">
        <img src="/images/error-icon.svg" alt="error icon" />
      </div>

      <p className="font-bold ">
        VEVO Check was unsuccessful. Please ensure that your full name is
        correct in the biographical information above, and that your passport
        number, issue country, and date of birth are correct.
      </p>
    </div>
  );
};

export default VevoError;
