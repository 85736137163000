import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "src/hooks/redux-hooks";
import {
  WorkrightProfile,
  Button,
  Checkbox,
  Jurisdiction,
  Title,
} from "src/components";
import { useQuestionnaireForm } from "src/hooks/useQuestionnaireForm";
import { useParams } from "react-router-dom";
import { submitCheck } from "src/services/apiService";

export interface QuestionnairePageProps {
  scrollToNextPage: () => void;
}

export const QuestionnairePage: React.FC<QuestionnairePageProps> = ({
  scrollToNextPage,
}) => {
  const jurisdictionValues = useAppSelector(
    (state) => state.questionnaire_form.jurisdiction
  );
  const { checkId } = useParams<{ checkId: string }>();
  const uploadedNonIdDocs = useAppSelector((state) => state.non_id_docs.docs);
  const requiredNonIdDocs = useAppSelector(
    (state) => state.non_id_docs.requiredDocs
  );
  const questionnaire_data = useAppSelector(
    (state: any) => state?.questionnaire_data?.questionnaire
  );

  const questionnaire_form = useAppSelector(
    (state: any) => state?.questionnaire_form
  );

  const [selectedDocument, setSelectedDocument] =
    useState<DocumentCollector | null>(null);
  console.log({ questionnaire_data });
  const [isAustralian, setIsAustralian] = useState(false);
  useEffect(() => {
    if (jurisdictionValues) {
      setIsAustralian(jurisdictionValues.code === "AU");
    }
  }, [jurisdictionValues]);

  const {
    registerWithClearErrors,
    errors,
    formData,
    showSubmit,
    setshowSubmit,
    updateFormValues,
  } = useQuestionnaireForm(isAustralian);
  const { firstName, middleName, lastName } = formData;
  const profile = {
    firstName,
    middleName,
    lastName,
  };
  const jurisdiction = formData.jurisdiction;
  const submitRef = useRef<HTMLDivElement>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVevoSuccess, setIsVevoSuccess] = useState(false);
  const [isVevoError, setIsVevoError] = useState(false);
  const [docsError, setDocsError] = useState<string | null>(null);

  const handleVevoCheck = async () => {
    try {
      setIsVevoSuccess(true);
      setshowSubmit(true);
      setIsAustralian(false);
    } catch (error) {
      setIsVevoError(true);
    }
  };

  const doc_collector = useAppSelector(
    (state: any) => state?.identity_doc_capture?.getDocumentCollector[0]
  );
  const status = useAppSelector((state) => state.status);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDocsError(null);
    if (isAustralian) {
      handleVevoCheck();
      // setIsAustralian(false);
    }
    if (showSubmit) {
      setIsSubmitting(true);
      try {
        const check_data = {
          _type: questionnaire_data._type,
          // pk: "string",
          // sk: "string",
          status_category_id: status.statusCategory?._id,
          status_type_id: status.statusType?._id,
          documents: {
            [doc_collector.key]: questionnaire_data._id,
          },
          data: questionnaire_form,
        };

        if (uploadedNonIdDocs.length < (requiredNonIdDocs?.length ?? 0)) {
          setDocsError("Please upload all required documents");
          return;
        }
        setSubmitted(true);
        await submitCheck(checkId, check_data);
        scrollToNextPage();
      } catch (error) {
        console.error("Error Submitting Form", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
  };

  useEffect(() => {
    if (showSubmit && submitRef.current && !isAustralian) {
      // scrollToElement(submitRef);
    } else {
      setIsChecked(false);
    }
  }, [showSubmit, isAustralian]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? You will need to resubmit the questionnaire.";
    };
    if (!submitted) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [submitted]);

  return (
    <div className="flex items-center justify-center pt-1">
      <form
        onSubmit={onSubmit}
        className={`w-full md:min-w-[455px] md:max-w-[455px] py-4 flex flex-col gap-3`}
      >
        {profile && (
          <WorkrightProfile
            register={registerWithClearErrors}
            profile={profile}
            errors={errors}
          />
        )}

        {jurisdiction && (
          <Jurisdiction
            setSelectedDocument={setSelectedDocument}
            selectedDocument={selectedDocument}
            isVevoSuccess={isVevoSuccess}
            isVevoError={isVevoError}
            setIsVevoSuccess={setIsVevoSuccess}
            isAustralian={isAustralian}
            setIsAustralian={setIsAustralian}
            register={registerWithClearErrors}
            jurisdiction={jurisdiction}
            errors={errors}
            formData={formData}
            setShowSubmit={setshowSubmit}
            updateFormValues={updateFormValues}
            checkId={checkId}
          />
        )}
        {docsError && (
          <p className="text-red-500 text-sm font-normal leading-4 text-left">
            {docsError}
          </p>
        )}

        {showSubmit && (
          <div
            ref={submitRef}
            className="text-left min-w-full md:min-w-[455px] md:max-w-[455px]"
          >
            <Title text="Submit" />
            <p className="font-inter text-sm font-normal leading-4 text-left pb-3">
              The above information is true and accurate to the best of your
              knowledge. Any misrepresentation to your work status may result in
              a delay of confirming your right to work and if you have not
              already started work, may result in a delay to starting your role.
            </p>
            <Checkbox
              id="submit"
              checked={isChecked}
              label={"I agree to the above"}
              checkboxStyle="acknowledgement"
              onChange={handleTerms}
            />
            <Button
              label={isSubmitting ? "Submitting..." : "Submit Response"}
              size="w-full mt-3"
              disabled={!isChecked || isSubmitting}
              type="submit"
            />
          </div>
        )}
      </form>
    </div>
  );
};
