import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { cn } from "../../../utils/tailwindMerger";
import { months } from "./utils";

type CalendarHeaderProps = {
  selectedMonth: number;
  setSelectedMonth: React.Dispatch<React.SetStateAction<number>>;
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  handleSelectMonth: (month: number) => void;
  setShowYears: React.Dispatch<React.SetStateAction<boolean>>;
  showYears: boolean;
  className?: string;
  monthClassName?: string;
  yearClassName?: string;
  toggleMonths: () => void;
};

/**
 * Renders the header of the calendar component.
 *
 * @param selectedMonth - The currently selected month.
 * @param setSelectedMonth - A function to set the selected month.
 * @param setSelectedYear - A function to set the selected year.
 * @param selectedYear - The currently selected year.
 * @param handleSelectMonth - A function to handle the selection of a month.
 * @param setShowYears - A function to show or hide the years.
 * @param showYears - A boolean indicating whether the years are shown or hidden.
 * @param className - Additional CSS class for the component.
 * @param monthClassName - Additional CSS class for the month element.
 * @param toggleMonths - A function to toggle between months.
 * @param yearClassName - Additional CSS class for the year element.
 */
const CalendarHeader = ({
  selectedMonth,
  setSelectedMonth,
  setSelectedYear,
  selectedYear,
  handleSelectMonth,
  setShowYears,
  showYears,
  className,
  monthClassName,
  toggleMonths,
  yearClassName,
}: CalendarHeaderProps) => {
  return (
    <div
      className={cn(
        "bg-steel flex items-center px-6 py-4 gap-2 text-sm justify-between",
        className
      )}
    >
      <div
        className={cn(
          "flex justify-between items-center  gap-1",
          monthClassName
        )}
      >
        <button
          onClick={() => {
            if (selectedMonth === 1) {
              setSelectedYear(selectedYear - 1);
            }
            handleSelectMonth(selectedMonth === 0 ? 11 : selectedMonth - 1);
          }}
          className="text-white font-bold"
          type="button"
        >
          {"<"}
        </button>
        <div
          role="button"
          onClick={toggleMonths}
          className="text-white font-bold uppercase"
        >
          {months[selectedMonth]["name"]}
        </div>
        <button
          type="button"
          onClick={() => {
            handleSelectMonth(selectedMonth === 11 ? 0 : selectedMonth + 1);

            if (selectedMonth === 11) {
              setSelectedYear(selectedYear + 1);
            }
          }}
          className="text-white font-bold"
        >
          {">"}
        </button>
      </div>

      <div
        role="button"
        onClick={() => setShowYears(!showYears)}
        className={cn(
          "text-white font-bold uppercase flex items-center gap-1",
          yearClassName
        )}
      >
        <span> {selectedYear} </span>
        <FontAwesomeIcon icon={faChevronDown} className="text-white" />
      </div>
    </div>
  );
};

export default CalendarHeader;
