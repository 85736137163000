import { ChangeEvent } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { HiOutlineXCircle } from "react-icons/hi";

interface TextfieldProps {
  label: string;
  size: string;
  placeholder?: string;
  value?: string;
  error?: string;
  success?: boolean;
  handleChangeInfo?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Textfield = ({
  placeholder,
  value,
  label,
  size,
  error,
  success,
  handleChangeInfo,
}: TextfieldProps) => {
  return (
    <div className="font-inter space-y-1.5">
      {label && (
        <label
          htmlFor={label}
          className="block font-inter font-medium text-sm leading-4 mb-1 text-black"
        >
          {label}
        </label>
      )}
      <div className="relative w-full">
        <input
          type="text"
          className={`${size} w-full h-3xl rounded-lg outline-none focus:ring-1 border ${error ? "border-2 border-red-500 ring-red-500" : "border-[#777777] ring-[#777777]"} ${success ? "border-2 border-green-500 ring-green-500" : "border-[#777777] ring-[#777777]"} bg-white shadow-[2px_4px_4px_rgba(0,0,0,0.13)] p-2 text-xs font-normal capitalize`}
          value={value}
          onChange={handleChangeInfo}
          placeholder={placeholder}
        />
        {success === true && (
          <HiOutlineCheckCircle className="absolute w-6 h-6 right-2 top-1/2 transform -translate-y-1/2 text-green-500" />
        )}
        {error && (
          <HiOutlineXCircle className="absolute w-6 h-6 right-2 top-1/2 transform -translate-y-1/2 text-red-500" />
        )}
      </div>
      {error && <p className="font-inter text-xs text-red-500">{error}</p>}
    </div>
  );
};
