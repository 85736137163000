import React from "react";

interface IdentityComparisonMismatchProps {
  frontSideImageUrl?: string;
  selfieImageUrl?: string;
  showHavingIssuesLink?: boolean;
  onReuploadDocument?: () => void;
  onRetakeSelfie?: () => void;
  onForceSubmit?: () => void;
}

export const IdentityComparisonMismatch = ({
  frontSideImageUrl,
  selfieImageUrl,
  showHavingIssuesLink = false,
  onReuploadDocument,
  onRetakeSelfie,
  onForceSubmit,
}: IdentityComparisonMismatchProps) => {
  return (
    <div className="bg-white w-full box-border p-6 sm:p-8 md:max-w-[45rem] md:px-10 md:py-11 rounded-2xl text-deepWater text-base leading-5 font-montserrat flex flex-col">
      <div className="top-section">
        <h2 className="font-bold font-inter text-lg leading-6 mb-2">
          Facial Comparison Mismatch
        </h2>
        <p className="font-normal font-inter">
          We could not successfully verify the document and selfie against each
          other. Please retake the selfie or provide a new document.
        </p>
      </div>
      {(frontSideImageUrl || selfieImageUrl) && (
        <div className="middle-section my-4 md:mt-16 px-4 flex flex-col items-center gap-4 md:flex-row md:justify-around md:items-center md:gap-0 md:px-0">
          {frontSideImageUrl && (
            <img
              className="w-full md:w-[16rem] md:h-[10rem] object-contain m-auto rounded-lg"
              src={frontSideImageUrl}
              alt="Front Side"
              width={256}
              height={160}
            />
          )}
          {selfieImageUrl && (
            <img
              className="w-full md:w-[16rem] md:h-[10rem] object-contain m-auto rounded-lg"
              src={selfieImageUrl}
              alt="Selfie"
              width={256}
              height={160}
            />
          )}
        </div>
      )}
      <div className="bottom-section p-2 w-full flex flex-col items-stretch gap-2 sm:flex-row sm:justify-center">
        <button
          type="button"
          className="flex-1 bg-azure font-inter hover:bg-mar text-white font-semibold py-2 text-center rounded cursor-pointer "
          onClick={onReuploadDocument}
        >
          Re-upload Document
        </button>
        <button
          type="button"
          className="flex-1 bg-azure font-inter hover:bg-mar text-white font-semibold py-2 text-center rounded cursor-pointer"
          onClick={onRetakeSelfie}
        >
          Retake Selfie
        </button>
      </div>
      {showHavingIssuesLink && (
        <div
          className="flex justify-end text-sm px-2 font-inter underline cursor-pointer"
          onClick={onForceSubmit}
        >
          Having issues? Submit document for review.
        </div>
      )}
    </div>
  );
};
