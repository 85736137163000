import { cn } from "src/utils/tailwindMerger";
import { Button } from "../../../../index";

interface CitizenshipProps {
  country: string;
  handleIsCitizen: (isCitizen: boolean) => void;
  showStatusCategories: boolean;
  isCitizen: boolean;
}

export const Citizenship = ({
  country,
  handleIsCitizen,
  showStatusCategories,
  isCitizen,
}: CitizenshipProps) => {
  return (
    <div className="mt-4">
      <p className="text-lg text-black font-inter font-medium mb-2">
        Are you a citizen of {country}?
      </p>
      <div className="flex flex-col gap-4 md:flex-row md:gap-2">
        <Button
          onClick={() => handleIsCitizen(true)}
          label="Yes, I am"
          size="w-full md:w-1/2"
          className={cn(
            showStatusCategories && isCitizen && "border-primary border-2"
          )}
        />
        <Button
          onClick={() => handleIsCitizen(false)}
          label="No, I'm not"
          size="w-full md:w-1/2"
          className={cn(
            showStatusCategories && !isCitizen && "border-primary border-2"
          )}
        />
      </div>
    </div>
  );
};
