import { createSlice } from "@reduxjs/toolkit";

export interface UploadedNonIdDocs {
  docs: DocumentCollector[];
  requiredDocs?: DocumentCollector[];
}

const initialState: UploadedNonIdDocs = {
  docs: [],
  requiredDocs: [],
};

export const uploadedNonIdDocsSlice = createSlice({
  name: "uploadedNonIdDocs",
  initialState,
  reducers: {
    addDoc: (state, action) => {
      if (state.docs.length === 0) {
        state.docs = [action.payload];
      } else {
        state.docs = state.docs.map((doc) =>
          doc._id === action.payload._id ? action.payload : doc
        );
      }
    },
    removeDoc: (state, action) => {
      state.docs = state.docs.filter((doc) => doc._id !== action.payload.id);
    },

    setRequiredDocs: (state, action) => {
      state.requiredDocs = action.payload;
    },
  },
});
export const { addDoc, removeDoc, setRequiredDocs } =
  uploadedNonIdDocsSlice.actions;

export default uploadedNonIdDocsSlice.reducer;
