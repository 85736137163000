import React from "react";
import { useSelector } from "react-redux";
import { cn } from "../../../utils/tailwindMerger";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: string;
  style?: string;
  type?: "button" | "submit";
  bgColor?: string;
  otpButton?: boolean;
  className?: string;
}

export const Button = ({
  label,
  disabled,
  type = "button",
  className,
  size,
  onClick,
  otpButton,
  bgColor = "#243745", // Fallback if no brand color
}: ButtonProps) => {
  let buttonStyle = `${size} ${otpButton ? "h-9" : "h-16"} flex-shrink-0 border border-solid border-black shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]`;

  const brand = useSelector(
    (state: any) =>
      state?.questionnaire_data?.questionnaire?.client?.branding.primaryColor ??
      bgColor
  );

  const backgroundColor = disabled ? "#949494" : brand;

  return (
    <button
      type={type}
      disabled={disabled}
      className={cn(
        buttonStyle,
        `font-inter border-2 rounded-lg inline-block text-white text-sm leading-5`,
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className
      )}
      style={{
        backgroundColor: otpButton === true ? "#243745" : backgroundColor,
      }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
