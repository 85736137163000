import Days from "./Days";
import Years from "./Years";
import CalendarHeader from "./CalendarHeader";
import { useEffect, useState } from "react";
import { cn } from "../../../utils/tailwindMerger";
import { isValidDate } from "../../../utils/utils";
import Months from "./Months";

export type DatePickerProps = {
  onDateChange: (date: Date) => void;
  value?: string;
  containerClassName?: string;
  daysClassName?: string;
  daysCellClassName?: string;
  headerClassName?: string;
  dateClassName?: string;
  monthClassName?: string;
  yearClassName?: string;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  darkMode?: boolean;
};

/**
 * DatePicker component.
 *
 * @component
 * @param {string} containerClassName - The class name for the container element.
 * @param {string} value - The value of the date picker.
 * @param {string} daysCellClassName - The class name for the days cell element.
 * @param {string} dateClassName - The class name for the date element.
 * @param {string} monthClassName - The class name for the month element.
 * @param {string} daysClassName - The class name for the days element.
 * @param {string} headerClassName - The class name for the header element.
 * @param {Date} selectedDate - The selected date.
 * @param {Function} setSelectedDate - The function to set the selected date.
 * @param {Function} onDateChange - The function to handle date change.
 * @param {boolean} darkMode - Whether to use dark mode or not. Default is true.
 * @returns {JSX.Element} The rendered DatePicker component.
 */
const DatePicker = ({
  containerClassName,
  value,
  daysCellClassName,
  dateClassName,
  monthClassName,
  daysClassName,
  headerClassName,
  selectedDate,
  setSelectedDate,
  onDateChange,
  darkMode = true,
}: DatePickerProps) => {
  const dateValue = isValidDate(value ?? "")
    ? new Date(value ?? "")
    : undefined;
  const today = dateValue ?? new Date();

  const [selectedMonth, setSelectedMonth] = useState<number>(today.getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(today.getFullYear());
  const [selectedDay, setSelectedDay] = useState<number>(today.getDate());
  const [showMonths, setShowMonths] = useState<boolean>(false);
  const [showYears, setShowYears] = useState<boolean>(false);

  const toggleMonths = () => {
    setShowMonths((prev) => !prev);
  };

  const handleSelectMonth = (month: number) => {
    setSelectedMonth(month);
    setShowMonths(false);
  };

  const handleSelectYear = (year: number) => {
    setSelectedYear(year);
    setShowYears(false);
  };

  useEffect(() => {
    if (selectedDate) {
      setSelectedMonth(selectedDate.getMonth());
      setSelectedYear(selectedDate.getFullYear());
      setSelectedDay(selectedDate.getDate());
    }
  }, [selectedDate]);

  return (
    <div className={cn("rounded-lg overflow-auto", containerClassName)}>
      <CalendarHeader
        toggleMonths={toggleMonths}
        className={headerClassName}
        handleSelectMonth={handleSelectMonth}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setSelectedMonth={setSelectedMonth}
        setSelectedYear={setSelectedYear}
        setShowYears={setShowYears}
        showYears={showYears}
      />
      <div
        className={cn(
          " overflow-auto relative ",
          daysClassName,
          darkMode ? "bg-iron text-white" : "bg-neutral text-steel"
        )}
      >
        {showYears && (
          <div className="absolute top-0 right-0 left-0 bg-iron">
            <Years
              setShowMonths={setShowMonths}
              selectedYear={selectedYear}
              handleSelectYear={handleSelectYear}
            />
          </div>
        )}
        {showMonths && (
          <div className=" bg-iron">
            <Months
              selectedMonth={selectedMonth}
              handleSelectMonth={handleSelectMonth}
            />
          </div>
        )}
        {!showMonths && (
          <Days
            onChange={onDateChange}
            darkMode={darkMode}
            dayClassName={daysCellClassName}
            monthClassName={monthClassName}
            dateClassName={dateClassName}
            className={daysClassName}
            selectedDate={selectedDate}
            selectedDay={selectedDay}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedDate={setSelectedDate}
            setSelectedDay={setSelectedDay}
            setSelectedMonth={setSelectedMonth}
          />
        )}
      </div>
    </div>
  );
};

export default DatePicker;
