import React from "react";
import { SuccessMessage } from "src/components";

export const SuccessPage = () => {
  return (
    <div className="flex items-center justify-center w-full h-full  ">
      <SuccessMessage
        heading="Success!"
        label="Your Right to Work check has been successfully submitted. You will be contacted via email if additional information is required to complete your check."
      />
    </div>
  );
};
