import { useForm, RegisterOptions } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState, useCallback } from "react";
import { schema, vevoSchema } from "../types/schema";
import { setFormData } from "../store/slices/questionnaireFormSlice";
import { useAppDispatch, useAppSelector } from "./redux-hooks";
import { useSelector } from "react-redux";

export const useQuestionnaireForm = (isVevoCheck = false) => {
  const dispatch = useAppDispatch();
  const questionnaireData = useAppSelector((state) => state.questionnaire_data);
  const formData = useAppSelector((state) => state.questionnaire_form);
  const profileData = useAppSelector((state) => state.profileData);
  const [showSubmit, setshowSubmit] = useState(false);

  const documentSubmitted = useSelector(
    (state: any) => state?.identity_doc_capture?.documentSubmitted,
  );

  const {
    register,
    trigger,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    resolver: zodResolver(isVevoCheck ? vevoSchema : schema),
    defaultValues: isVevoCheck ? { passport_number: "" } : formData,
  });

  const updateFormValues = useCallback(
    (data?: Partial<FormState>) => {
      // Get current values from the form
      const currentValues = getValues();

      const updatedValues = { ...currentValues, ...data };

      // Set form values and dispatch updated data
      Object.keys(updatedValues).forEach((key) => {
        setValue(key as string, updatedValues[key as keyof FormState], {
          shouldValidate: true,
          shouldDirty: true,
        });
      });

      dispatch(setFormData(updatedValues as FormState));
    },
    [getValues, setValue, dispatch],
  );

  const validateErrors = useCallback(async () => {
    // Trigger validation
    const isValid = await trigger();
    if (isValid) {
      // Clear errors if validation is successful
      clearErrors();
    }
  }, [trigger, clearErrors]);

  useEffect(() => {
    if (questionnaireData.questionnaire?.jurisdiction) {
      const { name, statusCategories } =
        questionnaireData.questionnaire.jurisdiction;

      const updateData: Partial<FormState> = {
        country: name,
        jurisdiction: {
          ...(questionnaireData.questionnaire.jurisdiction || {}),
          statusCategories,
        },
      };

      // Update form values and clear errors
      updateFormValues(updateData);
    }

    if (profileData) {
      const { firstName, lastName, middleName } = profileData.profile.name;
      const profile: Profile = {
        firstName,
        middleName,
        lastName,
      };
      // Update form values and clear errors
      updateFormValues({ ...profile });
      updateFormValues({ profile });
    }
  }, [questionnaireData, profileData, updateFormValues, dispatch]);

  useEffect(() => {
    // Watch for changes and update form data
    const subscription = watch((values) => {
      dispatch(setFormData(values as FormState));
      // Validate errors after changes
      validateErrors();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, dispatch, validateErrors]);

  useEffect(() => {
    // Validate errors on mount
    validateErrors();
  }, [validateErrors]);

  const registerWithClearErrors = (
    name: string,
    options?: RegisterOptions<FormState>,
  ) => {
    return {
      ...register(name, options),
      onChange: async (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        const isValid = await trigger(name);
        if (isValid) clearErrors(name);
        if (register(name, options).onChange) {
          register(name, options).onChange(e);
        }
      },
    };
  };

  useEffect(() => {
    if (documentSubmitted === true) {
      setshowSubmit(true);
    }
  }, [documentSubmitted]);

  return {
    errors,
    formData,
    showSubmit,
    setshowSubmit,
    registerWithClearErrors,
    register,
    validateErrors,
    handleSubmit,
    clearErrors,
    setValue,
    updateFormValues,
    getValues,
    trigger,
    watch,
  };
};
