import { DocumentCard, Title } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { IdentityDocumentUploader } from "../../../identity-document-capture/identity-document-uploader/IdentityDocumentUploader.tsx";
import "@regulaforensics/vp-frontend-face-components";
import { setDocCaptureData } from "../../../../../store/slices/identityDocCapture.ts";

interface DocumentCollectorsProps {
  documentCollectors: DocumentCollector[];
  setDocumentCollectors: React.Dispatch<
    React.SetStateAction<DocumentCollector[] | null>
  >;
  handleRequiredDocs: () => void;
  setSelectedDocument: React.Dispatch<
    React.SetStateAction<DocumentCollector | null>
  >;
}

export const DocumentCollectors = ({
  documentCollectors,
  handleRequiredDocs,
  setSelectedDocument,
  setDocumentCollectors,
}: DocumentCollectorsProps) => {
  const docCapture = useSelector(
    (state: any) => state?.identity_doc_capture?.docCaptureModalOpen ?? ""
  );

  const identityDocumentID = useSelector(
    (state: any) => state?.questionnaire_data?.questionnaire?._id ?? ""
  );

  const identityDocument = useSelector(
    (state: any) => state?.identity_doc_capture.identityDocument ?? ""
  );

  const dispatch = useDispatch();

  dispatch(
    setDocCaptureData({
      getDocumentCollector: documentCollectors,
    })
  );
  return (
    <div className="mt-4">
      <Title text="Required Documents" />
      <div className="flex flex-col gap-3">
        {documentCollectors.map((doc) => (
          <DocumentCard
            setDocumentCollectors={setDocumentCollectors}
            setSelectedDocument={setSelectedDocument}
            doc={doc}
            key={doc._id}
            title={doc.name.en!}
            isOptional={!doc.config.required}
            document={Object.keys(identityDocument).length !== 0}
            isPassportPhoto={identityDocument.images !== null}
            docNumber={
              identityDocument.data?.find(
                (item: { slug: string }) => item.slug === "document-number"
              )?.value
            }
            docIssueCountry={
              identityDocument.data?.find(
                (item: { slug: string }) => item.slug === "issuing-state-name"
              )?.value ?? ""
            }
            isIdentityDocument={false}
            selectedDocumentId={""}
            documentType={doc.documentType}
            uploadDocument={handleRequiredDocs}
            replaceDocument={handleRequiredDocs}
          />
        ))}
      </div>
      {docCapture && (
        <IdentityDocumentUploader
          setDocumentCollectors={setDocumentCollectors}
          identityDocumentID={identityDocumentID}
        />
      )}
    </div>
  );
};
