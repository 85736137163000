import { FieldErrors, FieldValues } from "react-hook-form";
import { FormField, Title } from "../../../../index";
import { formatString } from "../../../../../utils/utils";
import {
  DatePicker,
  LocationSearch,
} from "@simplecitizen/gdl-react-ui-components";
import React from "react";

interface DataCollectorsProps {
  errors: FieldErrors<FieldValues>;
  dataCollectors?: DataCollector[] | null;
  handleDataCollection: (key: string, value: string) => void;
  register: RegisterFn;
  formData: FormState;
}

export const DataCollectors = ({
  dataCollectors,
  handleDataCollection,
  register,
  formData,
  errors,
}: DataCollectorsProps) => {
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    key: string
  ) => handleDataCollection(key, e.target.value);

  const cleanKey = (key: string): string => formatString(key).replace(" ", "");

  const experianCheckRequired = dataCollectors?.find(
    (data) => data.key === "uk-experian-check"
  );

  console.log({ experianCheckRequired });

  return (
    <div className="mt-6">
      <Title text="Required Information" />
      {dataCollectors?.map((data) => (
        <FormField
          key={data._id}
          name={cleanKey(data.key)}
          label={data.label}
          formData={formData}
          options={data.options}
          subtype={data._subtype}
          errors={errors}
          register={register}
          handleSelect={(e) => {
            console.log("e", errors);
            handleSelectChange(e, cleanKey(data.key));
          }}
        />
      ))}

      {experianCheckRequired && (
        <div>
          <LocationSearch
            label="Primary Residential Address"
            labelClassName="font-normal text-sm"
            inputClassName="py-1"
            resultsContainerClassName="top-16"
          />
          <DatePicker
            labelClassName="font-normal text-sm"
            className="-mt-3"
            datePickerClassName="top-16"
            label="Date of Birth"
            value={new Date()}
            onDateChange={(date) => {
              console.log("date", date);
            }}
            inputClassName="py-1"
          />
        </div>
      )}
    </div>
  );
};
