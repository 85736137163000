interface TitleProps {
  text: string;
}

export const Title = ({ text }: TitleProps) => {
  return (
    <h4 className="font-inter font-normal text-black text-xl xl:text-2xl mb-2">
      {text}
    </h4>
  );
};
