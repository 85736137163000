import { ReactNode, ComponentProps, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

type ContentWrapProps = ComponentProps<"div"> & {
  children: ReactNode;
  className?: string;
};

const ContentWrapper = forwardRef<HTMLDivElement, ContentWrapProps>(
  ({ children, className = "", ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          "mx-auto w-full max-w-7xl px-8 md:px-[7rem]",
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default ContentWrapper;
