export const scrollToElement = (
  elementRef: React.RefObject<HTMLDivElement>
) => {
  if (elementRef.current) {
    elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const formatString = (str: string): string => {
  return str
    ?.replace(/[-_]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camelCase words
    .toLowerCase();
};

export function isValidDate(dateString: string): boolean {
  // Try to create a Date object from the string
  const date = new Date(dateString);

  // Check if the date is valid
  return !isNaN(date.getTime());
}

export const formatDate = (dateArg: Date) => {
  const date = new Date(dateArg);

  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0");
  const day = String(date?.getDate()).padStart(2, "0");
  const formattedDate = `${year}/${month}/${day}`;
  return formattedDate;
};

export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}
