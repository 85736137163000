import React from "react";

export const Checkmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    className="absolute inset-0 m-auto"
  >
    <path
      d="M15.7483 0.679708C16.0839 1.01535 16.0839 1.55808 15.7483 1.89015L6.3218 11.3202C5.98616 11.6558 5.44343 11.6558 5.11136 11.3202L0.25173 6.46413C-0.0839098 6.12849 -0.0839098 5.58576 0.25173 5.25369C0.587369 4.92162 1.1301 4.91805 1.46217 5.25369L5.71123 9.50274L14.5343 0.679708C14.8699 0.344069 15.4126 0.344069 15.7447 0.679708H15.7483Z"
      fill="#1D85DB"
    />
  </svg>
);


