import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";
import { InputField } from "./input-field/InputField";
import { SelectField } from "./select-field/SelectField";
import { formatDate, formatString } from "../../../utils/utils";
import { DateField } from "../date-picker/DateField";
import { SearchableDropdown } from "./dropdown/DropDown";

interface FormFieldProps {
  isMulti?: boolean;
  name: string;
  label: string;
  subtype: string;
  errors: FieldErrors<FieldValues>;
  register: RegisterFn;
  formData?: Partial<FormState>;
  options?: Option[];
  disabled?: boolean;
  handleSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleValues?: (formData?: Partial<FormState>) => void;
  placeholder?: string;
}

export const FormField = ({
  isMulti,
  name,
  label,
  subtype,
  errors,
  disabled,
  options,
  formData,
  register,
  handleSelect,
  handleValues,
  placeholder,
}: FormFieldProps) => {
  const customLabel = formatString(label);
  const newLabel = customLabel ?? label;

  const fieldProps = {
    disabled,
    name,
    register: register,
    error: errors[name as keyof FormState]?.message as string,
  };

  const noField = (
    <p className={"text-red-500 font-semibold leading-5"}>
      <ExclamationCircleIcon className="w-6 h-6 inline-block me-2" />
      <span className="capitalize">{subtype}</span> subtype item is not defined.
    </p>
  );

  const fieldComponents: { [key: string]: ReactNode } = {
    text: <InputField label={newLabel} type="text" {...fieldProps} />,
    email: <InputField type="email" label={newLabel} {...fieldProps} />,
    "searchable-dropdown": (
      <SearchableDropdown
        placeholder={placeholder}
        isMulti={isMulti}
        options={options || []}
        initialValue={formData && formData[name] ? formData[name] : ""}
        value={formData && formData[name] ? formData[name] : ""}
        onResultSelect={(val) => {
          handleValues && handleValues({ [name as keyof FormState]: val });
        }}
        label={newLabel}
        {...fieldProps}
      />
    ),
    date: (
      <DateField
        placeholder={placeholder}
        value={
          formData && formData[name] ? new Date(formData[name]) : new Date()
        }
        onDateChange={(date) => {
          handleValues &&
            handleValues({ [name as keyof FormState]: formatDate(date) });
        }}
        {...fieldProps}
      />
    ),
    select: (
      <SelectField
        {...fieldProps}
        options={options || []}
        value={formData && formData[name] ? formData[name] : ""}
        handleChange={handleSelect ? handleSelect : () => null}
      />
    ),
    "identity-document": (
      <SelectField
        {...fieldProps}
        options={options || []}
        value={formData && formData[name] ? formData[name] : ""}
        handleChange={handleSelect ? handleSelect : () => null}
      />
    ),
  };

  return (
    <div className="mb-3 min-w-[320px]">
      {newLabel && (
        <label
          htmlFor={name}
          className="block font-inter font-medium text-sm leading-4 mb-1 text-black capitalize"
        >
          {newLabel}
        </label>
      )}
      <div className="font-inter space-y-1.5">
        {fieldComponents[subtype] ?? noField}
        {fieldProps.error && (
          <p className="font-inter text-xs text-red-500">{fieldProps.error}</p>
        )}
      </div>
    </div>
  );
};
