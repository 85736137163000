import React from "react";
import { cn } from "../../../../utils/tailwindMerger";

interface ResultsProps {
  results: Option[];
  selectedOption: string;
  setSelectedOption: (value: React.SetStateAction<string>) => void;
  onResultSelect: (val: any) => void;
  setShowResults: (value: React.SetStateAction<boolean>) => void;
  handleAddOption: (option: Option) => void;
  handleRemoveOption: (option: Option) => void;
}

/**
 * Renders the results of a dropdown search.
 *
 * @param results - The array of search results.
 * @param setSelectedOption - A function to set the selected option.
 * @param selectedOption - The currently selected option.
 * @param onResultSelect - A function to handle the selection of a result.
 * @param setShowResults - A function to control the visibility of the results.
 * @param handleAddOption - A function to handle adding an option.
 */
const Results = ({
  results,
  setSelectedOption,
  selectedOption,
  onResultSelect,
  setShowResults,
  handleAddOption,
}: ResultsProps) => {
  return (
    <div className="bg-iron text-white w-full rounded-md md:max-h-[20rem] overflow-y-auto">
      {results.length > 0 ? (
        results.map((option) => (
          <div
            key={option.value}
            className={cn(
              "p-2 cursor-pointer hover:bg-steel",
              selectedOption === option.value ? "bg-steel" : ""
            )}
            onClick={() => {
              setSelectedOption(option.value);
              onResultSelect(option.label);
              handleAddOption(option);
              setShowResults(false);
            }}
          >
            {option.label}
          </div>
        ))
      ) : (
        <div className="p-2 text-center text-sm text-gray-400">
          No results found
        </div>
      )}
    </div>
  );
};

export default Results;
