import { DefaultLogo } from "../default-logo/DefaultLogo.tsx";
import { ComponentProps } from "react";
import { cn } from "../../../utils/tailwindMerger";
import ContentWrapper from "../content-wrapper/ContentWrapper.tsx";
import { useSelector } from "react-redux";

const defaultLabel = "Right to Work Confirmation Portal";

type HeaderProps = ComponentProps<"header"> & {
  label?: string;
  className?: string;
  scroll?: boolean;
};

export const Header = ({
  label = defaultLabel,
  className,
  scroll,
  ...props
}: HeaderProps) => {
  const logo = useSelector(
    (state: any) =>
      state?.questionnaire_data?.questionnaire?.client?.branding?.logo || ""
  );

  return (
    <header
      className={cn(
        "flex md:justify-start justify-center items-center h-full pt-4 pb-1 transition-all duration-300  max-sm:pt-6 shadow-[black,0px,0.2px,15px,0px]",

        className
        // scroll && "shadow-[rgba(0 0 0 / 10%)_0px_0.2px_15px_0px]"
      )}
      {...props}
      style={{
        boxShadow: scroll ? "0px 0.2px 15px 0px rgb(0 0 0 / 0.2)" : "none",
      }}
    >
      <ContentWrapper>
        <DefaultLogo label={label} src={logo} />
      </ContentWrapper>
    </header>
  );
};
